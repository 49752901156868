import React, { useEffect } from 'react';
import Layout from "../components/layout"
import Hero from "../components/Hero"
import contactHero from "../images/Contact Hero.png"
import handyman from "../images/handyman4.svg"

export default function ContactPage() {
    useEffect(() => {
        window.Tally.loadEmbeds();
    });
    return (
        <Layout>
            <Hero heroData={data.heroData} />
            <div class="pt-10 pb-20 flex flex-wrap">
                <div class="md:w-1/2">
                    <div class="text-hp-gold text-xl md:text-4xl font-semi-bold leading-tight">
                        We'd love to hear from you!
                    </div>
                    <div class="md:w-2/3 md:pt-2">
                        Please email us at <a href="mailto: hello@homeplan.com.au">hello@homeplan.com.au</a> or fill in this contact form and we’ll get back to you shortly.
                    </div>
                    <div class="md:mt-4 -mb-16 hidden md:block">
                        <img class="lg:w-3/5 md:h-64" src={data.image} />
                    </div>
                </div>
                <div class="md:pt-2 pt-6 md:w-1/2 w-full">
                    <iframe data-tally-src="https://tally.so/embed/w2Ed4M?hideTitle=1&amp;transparentBackground=1&amp;dynamicHeight=1" width="100%" height="332" frameborder="0" marginheight="0" marginwidth="0" title="Homeplan Contact"></iframe>
                </div>
            </div>
        </Layout>
    )
}

const data = {
    heroData: {
        image: contactHero,
        headline: "Want to know more about what we do and how we can help you?",
    },
    image: handyman
}